import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import axios from "axios";

const AdminLogin = () => {
  useEffect(() => {
      document.title = "TI| Admin-Login";
    }, []);
  const [username, setUsername] = useState("");  // Use username instead of email
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    Swal.fire({
      title: "Processing...",
      html: `
          <div class="custom-loading">
              <div class="spinner">
                  <img src="/teemah.png" alt="Loading" />
              </div>
              <p>Please hold on while we authenticate your credentials.</p>
          </div>
      `,
      showConfirmButton: false, // Hide confirm button during loading
      allowOutsideClick: false,
      allowEscapeKey: false,
      

  });

  
    try {
      // Prepare the data to send
      const data = {
        username,  // Now sending the username instead of email
        password,  // Include the password for authentication
      };
  
      // Send the login request to get the JWT token
      const response = await axios.post(
        "https://teemah.com.ng/api/token/", // Use the endpoint for token generation
        data
      );
  
      // Log the full response for debugging
      console.log("Response:", response.data);
  
      if (response.status === 200) {
        // If login is successful, store the JWT token
        const { access, refresh } = response.data;

        // Store the JWT access token and refresh token in localStorage
        localStorage.setItem("access_token", access);
        localStorage.setItem("refresh_token", refresh);
  
        // Show success message
        Swal.fire({
          icon: "success",
          title: response.data.message || "Login Successful",
          text: `Welcome, ${response.data.user?.username || "Admin"}!`,
          timer: 2000, // Automatically close after 2 seconds
          showConfirmButton: false,
        }).then(() => {
          navigate("/admin-dashboard"); // Redirect after alert
        });
      } else {
        // If login failed
        Swal.fire({
          icon: "error",
          title: "Login Failed",
          text: response.data.message || "Invalid username or password.",
        });
      }
    } catch (error) {
      console.error("Error during login:", error);
  
      if (error.response) {
        // If error response from the server
        Swal.fire({
          icon: "error",
          title: "Login Failed",
          text: error.response.data.message || "An error occurred, please try again.",
        });
      } else if (error.request) {
        // If no response from the server
        Swal.fire({
          icon: "error",
          title: "Login Failed",
          text: "No response from the server. Please check your network or try again later.",
        });
      } else {
        // General error
        Swal.fire({
          icon: "error",
          title: "Login Failed",
          text: "An error occurred, please try again later.",
        });
      }
    }
  };

  return (
    <div className="relative min-h-screen bg-black flex items-center justify-center px-4 md:px-2 sm:px-4">
  <div className="max-w-md w-full bg-gradient-to-br from-yellow-500 to-orange-800 shadow-lg rounded-tr-3xl rounded-bl-3xl p-8 z-50 my-8">
    <h1 className="text-3xl font-bold font-limelight text-center text-black mb-6">
      Admin Login
    </h1>
    <form onSubmit={handleLogin} className="space-y-6">
      <div>
        <label className="block font-medium mb-1 text-black">Username:</label>
        <input
          type="text"
          name="username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          placeholder="Enter your username"
          className="w-full border border-gray-300 rounded-lg h-12 px-4 py-2 focus:outline-none focus:ring-2 focus:ring-gray-500 bg-white text-black"
          required
        />
      </div>
      <div>
        <label className="block font-medium mb-1 text-black">Password:</label>
        <input
          type="password"
          name="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Enter your password"
          className="w-full border border-gray-300 rounded-lg h-12 px-4 py-2 focus:outline-none focus:ring-2 focus:ring-gray-500 bg-white text-black"
          required
        />
      </div>
      <div className="text-center">
        <button
          type="submit"
          className="bg-yellow-500 text-gray-800 px-6 py-3 rounded-lg shadow hover:bg-blue-600 transition-colors"
        >
          Login
        </button>
      </div>
    </form>
    {/* Watermark Image */}
    <img
      src="/teemah.png" // Replace with your watermark image path
      alt="Watermark"
      className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 opacity-10 w-3/4 sm:w-3/3 md:w-1/4 lg:w-1/6 pointer-events-none"
    />
  </div>
</div>


  );
};

export default AdminLogin;
