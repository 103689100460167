import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import axios from "axios";

const InvitationForm = () => {
    useEffect(() => {
      document.title = "TI | Create-IV";
    }, []);
    const navigate = useNavigate();

    // Table configuration state
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        category: "Parents", // Default category
        table_number: "",
    });

    

    // List of available tables
    const availableTables = {
        "Special Guest of Honour": [
            { tableNumber: 1, tableName: "Table One" },
            { tableNumber: 2, tableName: "Table Two" },
            // { tableNumber: 3, tableName: "Table Three" },
            // { tableNumber: 4, tableName: "Table Four" },
            // { tableNumber: 5, tableName: "Table Five" },
            // { tableNumber: 6, tableName: "Table Six" },
            // { tableNumber: 7, tableName: "Table Seven" },
            // { tableNumber: 8, tableName: "Table Eight" },
            // { tableNumber: 9, tableName: "Table Nine" },
            // { tableNumber: 10, tableName: "Table Ten" },
            // { tableNumber: 11, tableName: "Table Eleven" },
            
        ],

        "Committee of Event": [
            
            { tableNumber: 3, tableName: "Table Three" },
            { tableNumber: 4, tableName: "Table Four" },
            
        ],

        Parents: [
            
            { tableNumber: 5, tableName: "Table Five" },
            { tableNumber: 6, tableName: "Table Six" },
            // { tableNumber: 7, tableName: "Table Seven" },
            // { tableNumber: 8, tableName: "Table Eight" },
            // { tableNumber: 9, tableName: "Table Nine" },
            // // { tableNumber: 10, tableName: "Table Ten" },
            // { tableNumber: 11, tableName: "Table Eleven" },
            // // { tableNumber: 12, tableName: "Table Twelve" },
            // { tableNumber: 13, tableName: "Table Thirteen" },
            // { tableNumber: 14, tableName: "Table Fourteen" },
            // // { tableNumber: 19, tableName: "Table Nineteen" },
            // { tableNumber: 20, tableName: "Table Twenty" },
            // // { tableNumber: 21, tableName: "Table Twenty-One" },
            
            // // { tableNumber: 22, tableName: "Table Twenty-Two" },
            

            // { tableNumber: 26, tableName: "Table Twenty-Six" },
            // { tableNumber: 27, tableName: "Table Twenty-Seven" },

            // { tableNumber: 29, tableName: "Table Twenty-Nine" },
            // { tableNumber: 30, tableName: "Table Thirty" },
            
            
           
        ],

        "Prospective Parents": [
            // { tableNumber: 5, tableName: "Table Five" },
            // { tableNumber: 6, tableName: "Table Six" },
            { tableNumber: 7, tableName: "Table Seven" },
            { tableNumber: 8, tableName: "Table Eight" },
            // { tableNumber: 9, tableName: "Table Nine" },
            // { tableNumber: 10, tableName: "Table Ten" },
            // { tableNumber: 11, tableName: "Table Eleven" },
            // // { tableNumber: 12, tableName: "Table Twelve" },
            // { tableNumber: 13, tableName: "Table Thirteen" },
            // { tableNumber: 14, tableName: "Table Fourteen" },
            // { tableNumber: 19, tableName: "Table Nineteen" },
            // { tableNumber: 20, tableName: "Table Twenty" },
            // { tableNumber: 21, tableName: "Table Twenty-One" },
            
            // { tableNumber: 22, tableName: "Table Twenty-Two" },
            

            // { tableNumber: 26, tableName: "Table Twenty-Six" },
            // { tableNumber: 27, tableName: "Table Twenty-Seven" },

            // { tableNumber: 29, tableName: "Table Twenty-Nine" },
            // { tableNumber: 30, tableName: "Table Thirty" },
            
               
            
        ],

        "External Guest": [
            // { tableNumber: 5, tableName: "Table Five" },
            // { tableNumber: 6, tableName: "Table Six" },
            // { tableNumber: 7, tableName: "Table Seven" },
            // { tableNumber: 8, tableName: "Table Eight" },
            { tableNumber: 9, tableName: "Table Nine" },
            { tableNumber: 10, tableName: "Table Ten" },
            
            // { tableNumber: 19, tableName: "Table Nineteen" },
            // { tableNumber: 20, tableName: "Table Twenty" },
            // { tableNumber: 21, tableName: "Table Twenty-One" },
            
            // { tableNumber: 22, tableName: "Table Twenty-Two" },
            

            // { tableNumber: 26, tableName: "Table Twenty-Six" },
            // { tableNumber: 27, tableName: "Table Twenty-Seven" },

            // { tableNumber: 29, tableName: "Table Twenty-Nine" },
            // { tableNumber: 30, tableName: "Table Thirty" },
            
            
        ],
       

        Alumni: [
          { tableNumber: 11, tableName: "Table Fifteen" },
          { tableNumber: 12, tableName: "Table Sixteen" },
            
            
        ],

        "Previous Parents": [
            // { tableNumber: 11, tableName: "Table Eleven" },
            // { tableNumber: 12, tableName: "Table Twelve" },
            { tableNumber: 13, tableName: "Table Thirteen" },
            { tableNumber: 14, tableName: "Table Fourteen" },
           
          // { tableNumber: 17, tableName: "Table Seventeen" },
          // { tableNumber: 18, tableName: "Table Eighteen" },
          // { tableNumber: 28, tableName: "Table Twenty-Eight" },
            
        ],
        
        "Marzwell Staff": [
          { tableNumber: 15, tableName: "Table Fifteen" },
          { tableNumber: 15, tableName: "Table Sixteen" },
         
          
            // { tableNumber: 23, tableName: "Table Twenty-Three" },

            // { tableNumber: 24, tableName: "Table Twenty-Four" },
            // { tableNumber: 25, tableName: "Table Twenty-Five" }
            
        ],
    };

    
    
    // Handle form data changes
    const handleChange = (e) => {
        const { name, value } = e.target;
        // if (name === "category" && value === "Alumni") {
        //     Swal.fire({
        //         title: "Attention!",
        //         html: `Dear <strong> ${formData.name} </strong>, this dinner is only for alumni who are undergraduates or graduates. <p> <strong>Non-eligible guests</strong> will not be allowed entry. Thank you for understanding.</>`,

        //         icon: "info",
        //         confirmButtonText: "Okay",
        //         customClass: {
        //             popup: 'bg-yellow-100 border-4 border-yellow-500 text-black w-11/12 md:w-1/4', // Popup styling
        //             title: 'text-black font-bold', // Title styling
        //             content: 'text-black', // Content text styling
        //             confirmButton: 'bg-yellow-500 text-black font-bold p-4 rounded hover:bg-yellow-600' // Confirm button styling
        //         }
        //     });
            
        //   }
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    

   
    const handleSubmit = async (e) => {
        e.preventDefault();
        Swal.fire({
          title: "Processing...",
          html: `
              <div class="custom-loading">
                  <div class="spinner">
                      <img src="/teemah.png" alt="Loading" />
                  </div>
                  <p>Please hold on while your invitation submission is being processed."</p>
              </div>
          `,
          showConfirmButton: false, // Hide confirm button during loading
          allowOutsideClick: false,
          allowEscapeKey: false,
          
    
      });
    
     
        try {
            const response = await axios.post("https://teemah.com.ng/api/create-invitation/", formData);

            // const response = await axios.post("http://127.0.0.1:8000/api/create-invitation/", formData);
    
            const invitationId = response.data.Invitation_number; // Get the invitation ID from response
            Swal.fire({
                title: "Success!",
                text: "Your invitation has been created successfully.",
                icon: "success",
                confirmButtonText: `Please, click to get <strong> ${formData.name}'s</strong>
                 Invitation Card`,
                customClass: {
                    popup: 'bg-yellow-100 border-4 border-yellow-500 text-black w-11/12 md:w-1/4', // Popup styling
                    title: 'text-black font-bold', // Title styling
                    content: 'text-black', // Content text styling
                    confirmButton: 'bg-yellow-500 text-black font-bold p-4 rounded hover:bg-yellow-600' // Confirm button styling
                }
            }).then(() => {
                navigate(`/receipt/${invitationId}`);
            });
    
        } catch (error) {
            console.error("Error creating invitation:", error);
            
            // Check if the backend sent an error response
            if (error.response) {
                const errorMessage = error.response.data.detail || "An unknown error occurred.";
                // Display the error message in a Swal alert
                Swal.fire({
                    title: "Error!",
                    text: errorMessage,
                    icon: "error",
                    confirmButtonText: "Okay",
                });
            } else {
                // Handle network errors or unknown issues
                Swal.fire({
                    title: "Error!",
                    text: "Network error or server not responding. Please try again later.",
                    icon: "error",
                    confirmButtonText: "Okay",
                });
            }
        }
    };
    

    return (
        <div className="relative min-h-screen bg-black flex items-center justify-center px-0 md:px-2 sm:px-2 pb-32 md:pb-2">
    <div className="max-w-md w-full bg-gradient-to-br from-yellow-500 to-orange-800 shadow-lg rounded-tr-3xl rounded-bl-3xl p-8 z-50 my-8">
        <h1 className="text-3xl font-bold font-limelight text-center text-blue-800 mb-6">
            Invitation Form
        </h1>
        <form onSubmit={handleSubmit} className="space-y-6">
            <div>
                <label className="block font-medium mb-1 text-black">Name:</label>
                <input
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    required
                    className="w-full border border-gray-300 rounded-lg p-3 focus:outline-none focus:ring-2 focus:ring-gray-500 text-black "
                />
            </div>
            <div>
                <label className="block font-medium mb-1 text-black">Email:</label>
                <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                    className="w-full border border-gray-300 rounded-lg p-3 focus:outline-none focus:ring-2 focus:ring-gray-500  text-black"
                />
            </div>
            <div>
  {/* <!-- Category Field --> */}
  <label className="block font-medium mb-1 text-black">Category:</label>
  <select
    name="category"
    value={formData.category}
    onChange={handleChange}
    className="w-full border border-gray-300 rounded-lg h-12 px-4 py-2 focus:outline-none focus:ring-2 focus:ring-gray-500 appearance-none -webkit-appearance-none bg-white text-black"
    required
  >
    <option
      className="bg-gray-400 hover:bg-gray-500"
      value="Special Guest of Honour"
    >
      Special Guest Of Honour
    </option>
    <option
      className="bg-gray-400 hover:bg-gray-500"
      value="Committee of Event"
    >
      Bride's Parents
    </option>
    <option className="bg-gray-400 hover:bg-gray-500" value="Parents">
      Parents
    </option>
    <option
      className="bg-gray-400 hover:bg-gray-500"
      value="Prospective Parents"
    >
      Grooms Parents
    </option>
    <option className="bg-gray-400 hover:bg-gray-500" value="External Guest">
     Bride's Maid
    </option>
    <option
      className="bg-gray-400 hover:bg-gray-500"
      value="Previous Parents"
    >
      Brides Well Wishers
    </option>
    <option className="bg-gray-400 hover:bg-gray-500" value="Alumni">
      Groom's Friends
    </option>
    <option className="bg-gray-400 hover:bg-gray-500" value="Marzwell Staff">
      Groom's Well Wishers
    </option>
  </select>
</div>

<div className="mt-4">
  {/* <!-- Table Number Field --> */}
  <label className="block font-medium mb-1 text-black">Table Number:</label>
  <select
    name="table_number"
    value={formData.table_number}
    onChange={handleChange}
    className="w-full border border-gray-300 rounded-lg h-12 px-4 py-2 focus:outline-none focus:ring-2 focus:ring-yellow-500 appearance-none -webkit-appearance-none bg-white text-black"
    required
  >
    <option value="" disabled>
      Select a Table
    </option>
    {availableTables[formData.category]?.map((table) => (
      <option key={table.tableNumber} value={table.tableNumber}>
        {table.tableName}
      </option>
    ))}
  </select>
</div>

            <div className="text-center">
                <button
                    type="submit"
                    className="bg-blue-800 text-white px-6 py-3 rounded-lg shadow hover:bg-blue-600 transition-colors"
                >
                    Submit Invitation
                </button>
            </div>
        </form>
        {/* Watermark Image */}
    <img
      src="/teemah.png" // Replace with your watermark image path
      alt="Watermark"
      className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 opacity-10 w-3/4 sm:w-3/3 md:w-1/4 lg:w-1/6 pointer-events-none"
    />
    </div>
</div>

    
    );
};

export default InvitationForm;
