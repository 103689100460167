import React, { useState, useEffect, useRef } from "react"; 
import { Link, useNavigate } from "react-router-dom";
import { FaHome, FaPhone, FaEnvelope, FaCheckCircle, FaBars, FaTimes, FaComment, FaPencilAlt, FaMobileAlt, FaCompass, FaArrowAltCircleRight, FaSignInAlt, FaDownload, FaEllipsisH, } from "react-icons/fa";
import Swal from "sweetalert2";
import "./sidebar.css"
import { TbPackages } from "react-icons/tb";


const Sidebar = () => {
  const [showMore, setShowMore] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(true);

  const toggleSidebar = () => {
    setIsCollapsed(!isCollapsed);
  };

  const navigate = useNavigate();
  const [showPrompt, setShowPrompt] = useState(false);
  const [code, setCode] = useState("");
  const dropdownRef = useRef(null);

  const handleCodeSubmit = () => {
    if (code === "ojo") {
      setShowPrompt(false); // Add this line

      navigate("/create-invitation");
    } else {
      Swal.fire({
        title: "Error!",
        text: "Invalid code. Please try again.",
        icon: "error",
        confirmButtonText: "Okay",
      });
    }
  };

  const handleCancel = () => {
    setCode(''); // Reset the input field
    window.location.href = '/'; // Redirect to the home page
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowMore(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Close dropdown when a menu item is clicked
  const handleMenuItemClick = () => {
    setShowMore(false);
  };
  




  return (
    <div className="flex flex-col md:flex-row z-10 fixed ">
      {/* Sidebar container (hidden on small screens) */}
      <div
        className={`${
          isCollapsed ? "md:w-16" : "md:w-64"
        } bg-yellow-500 text-blue-800 h-screen transition-all duration-300 md:block hidden `}
      >
        <Link to="/"><img src="/teemah.png" alt="Teemah" className="mt-5 p-1 h-auto w-20" /></Link>
        <div className="flex items-center justify-between p-4 mt-10">
          {/* Toggle button */}
          <button onClick={toggleSidebar} className="text-black">
            {isCollapsed ? <FaBars /> : <FaTimes />}
          </button>
        </div>
        
        {/* Sidebar Links */}
        <div className=" flex flex-col items-center z-10">
  <Link to="/" className="flex items-center p-4 hover:bg-yellow-500 transition-colors w-full" title="Home" >
    {isCollapsed ? <FaHome /> : <><FaHome className="mr-2" /> Home</>}
  </Link>
  <Link to="/contact" className="flex items-center p-4 hover:bg-yellow-500 transition-colors w-full" title="Contact Us" >
    {isCollapsed ? <FaPhone /> : <><FaPhone className="mr-2" /> Contact Us</>}
  </Link>
  <Link to="/packages" className="flex items-center p-4 hover:bg-yellow-500 transition-colors w-full" title="Event Packages" >
    {isCollapsed ? <TbPackages /> : <><TbPackages className="mr-2" /> Packages</>}
  </Link>
  <Link to="/create-invitation" className="flex items-center p-4 hover:bg-yellow-500 transition-colors w-full" title="Create Invitation" >
    {isCollapsed ? <FaEnvelope /> : <><FaEnvelope className="mr-2" /> Packages</>}
  </Link>
  {/* <Link to="/feedback" className="flex items-center p-4 hover:bg-yellow-500 transition-colors w-full" title="Feedback">
    {isCollapsed ? <FaComment /> : <><FaComment className="mr-2" /> Feedback</>}
  </Link> */}
  <Link to="/reg-email" className="flex items-center p-4 hover:bg-yellow-500 transition-colors w-full" title="Registration Form" >
    {isCollapsed ? <FaPencilAlt /> : <><FaPencilAlt className="mr-2" /> Registration Form</>}
  </Link>
  




  <Link to="/smart-iv" className="flex items-center p-4 hover:bg-yellow-500 transition-colors w-full" title="Smart E-Invites Samples" >
    {isCollapsed ? <FaMobileAlt /> : <><FaMobileAlt className="mr-2" /> Smart E-Invite Samples</>}
  </Link>
  
  <Link to="/explore-more" className="flex items-center p-4 hover:bg-yellow-500 transition-colors w-full" title="Explore More" >
    {isCollapsed ? <FaCompass /> : <><FaCompass className="mr-2" /> Explore More</>}
  </Link>
  <Link to="/admin-login" className="flex items-center p-4 hover:bg-yellow-500 transition-colors w-full" title="Admin Login" >
    {isCollapsed ? <FaSignInAlt /> : <><FaSignInAlt className="mr-2" />Admin Login</>}
  </Link>
  <Link to="/go-to-receipt" className="flex items-center p-4 hover:bg-yellow-500 transition-colors w-full" title="Download IV">
    {isCollapsed ? <FaDownload /> : <><FaDownload className="mr-2" /> Download IV</>}
  </Link>
</div>


      </div>

      

      {/* Bottom Navbar (visible only on small screens) */}
      <div className="flex md:hidden fixed bottom-0 left-0 right-0 bg-yellow-500 text-black p-4 justify-between z-100">
  <div className="flex overflow-x-auto whitespace-nowrap ">
    <Link to="/" className="flex flex-col items-center mx-4 ">
      <FaHome />
      <span>Home</span>
    </Link>
    <Link to="/contact" className="flex flex-col items-center mx-4">
      <FaPhone />
      <span>Contact</span>
    </Link>
    
    
    <Link to="/create-invitation" className="flex flex-col items-center mx-4">
      <FaEnvelope />
      <span>Invitation</span>
    </Link>
    <Link to="/packages" className="flex flex-col items-center mx-4">
      <TbPackages />
      <span>Packages</span>
    </Link>
  </div>

  {/* "More" button to toggle extra menu items */}
  <div className="relative " ref={dropdownRef}>
    <button
      onClick={() => setShowMore(!showMore)}
      className="flex flex-col items-center mx-4"
    >
      <FaEllipsisH />
      <span>More</span>
    </button>
    {showMore && (
      <div className="absolute bottom-12 right-0 bg-white text-black rounded-md shadow-lg p-4 w-max z-100">
        <Link to="/packages" className="block mb-2" onClick={handleMenuItemClick}>
          <TbPackages className="inline-block mr-2" />
          Packages
        </Link>
        <Link to="/smart-iv" className="block mb-2" onClick={handleMenuItemClick}>
          <FaMobileAlt className="inline-block mr-2" />
          Smart E-Invite
        </Link>
        <Link to="/reg-email" className="block mb-2" onClick={handleMenuItemClick}>
      <FaPencilAlt className="inline-block mr-2" />
      <span>Registration</span>
    </Link>
        <Link to="/explore-more" className="block mb-2" onClick={handleMenuItemClick}>
          <FaCompass className="inline-block mr-2" />
          Explore More
        </Link>
        <Link to="/admin-login" className="block mb-2" onClick={handleMenuItemClick}>
          <FaSignInAlt className="inline-block mr-2" />
          Admin Login
        </Link>
        <Link to="/go-to-receipt" className="block mb-2" onClick={handleMenuItemClick}>
          <FaDownload className="inline-block mr-2" />
          Download IV
        </Link>
      </div>
    )}
  </div>
</div>







    </div>
  );
};

export default Sidebar;
