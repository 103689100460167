import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';

const LandingPage = () => {
  useEffect(() => {
      document.title = "TI |Re-download IV";
    }, []);
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");

  // Function to handle email submission
  const handleSubmit = async () => {
    if (!email) {
      setError("Email is required.");
      return;
    }

    try {
      // Replace with your backend API endpoint
      const response = await fetch("https://teemah.com.ng/api/get-receipt-by-email/", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email }),
      });

      // const response = await fetch("http://127.0.0.1:8000/api/get-receipt-by-email/", {
      //   method: "POST",
      //   headers: { "Content-Type": "application/json" },
      //   body: JSON.stringify({ email }),
      // });

      if (!response.ok) {
        throw new Error("Failed to fetch receipt details.");
      }

      const data = await response.json();
      const invitationId = data.id; // Adjust based on the API response structure
      setIsModalOpen(false);
      navigate(`/receipt/${invitationId}`);
    } catch (err) {
      setError("Failed to fetch receipt. Please check the email and try again.");
      console.error(err);
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-black via-gray-900 to-gray-800 text-white flex flex-col md:flex-row relative">
  {/* Left Container */}
  <div className="relative w-full md:w-1/2  md:h-screen flex flex-col items-center justify-center p-6 md:p-12 text-center">
    <h1 className="text-3xl md:text-4xl font-extrabold text-yellow-500 mb-4">
      Re-Download Your Invitation Card
    </h1>
    <p className="text-gray-300 text-lg leading-relaxed">
      Simply click the button below, then enter your email address to receive
      your new invitation card.
    </p>
    <div className="mt-8">
      <button
        onClick={() => setIsModalOpen(true)}
        className="bg-yellow-500 text-black px-8 py-4 rounded-full font-semibold text-lg shadow-lg hover:bg-yellow-600 transition duration-300"
      >
        Download Invitation Card
      </button>
    </div>
  </div>

  {/* Right Side Content */}
  <div className="w-full md:w-1/2 h-full flex items-center justify-center p-6 md:p-12 bg-gradient-to-r from-yellow-400 to-yellow-500 shadow-lg rounded-lg">
  <div className="text-center md:text-left space-y-6 max-w-lg">
    <h2 className="text-4xl font-extrabold text-gray-900">
      It's Quick and Easy!
    </h2>
    <p className="text-gray-800 text-lg leading-relaxed">
      Secure your seat with just a few clicks. Ensure you never miss out on this
      special event!
    </p>
    <div className="bg-white p-4 rounded-lg shadow-md space-y-4">
      <h3 className="text-2xl font-semibold text-yellow-600">Why Redownload?</h3>
      <ul className="list-disc list-inside text-gray-700 space-y-2 text-justify">
        <li>Your table/seat were changed by the admin or host.</li>
        <li>You can no longer find your invitation card.</li>
        <li>Your name or other information was edited by the host.</li>
      </ul>
    </div>
  </div>
</div>


  {/* Modal */}
  {isModalOpen && (
    <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50">
      <div className="bg-gray-800 p-8 rounded-lg w-11/12 md:w-1/3">
        <h2 className="text-xl font-bold mb-6 text-center text-yellow-500">
          Enter Your Email
        </h2>
        <input
          type="email"
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
            setError(""); // Clear error when typing
          }}
          placeholder="Enter your email"
          className="w-full p-3 bg-gray-900 text-white border border-gray-700 rounded-lg mb-4 focus:outline-none focus:ring-2 focus:ring-yellow-500"
        />
        {error && (
          <p className="text-red-500 text-sm text-center mb-4">{error}</p>
        )}
        <div className="flex justify-between">
          <button
            onClick={() => setIsModalOpen(false)}
            className="bg-gray-700 text-white px-4 py-2 rounded-lg hover:bg-gray-600 transition"
          >
            Cancel
          </button>
          <button
            onClick={handleSubmit}
            className="bg-yellow-500 text-black px-4 py-2 rounded-lg hover:bg-yellow-600 transition"
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  )}

  {/* Hidden Admin Login Link */}
</div>



  );
};

export default LandingPage;