import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

const ThankYouPage = () => {

  useEffect(() => {
    document.title = "Teemah Invites | Home";
  }, []);




  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleExploreMore = () => {
    navigate("/explore-more");
  };

  return (
    <div className="min-h-screen bg-black flex flex-col relative w-full mb-20">
  {/* Main container with left and right sections */}
  <div className="flex flex-col md:flex-row w-full">
    {/* Left container */}
    <div className="relative w-full md:w-1/2 h-80 md:h-screen flex flex-col items-center justify-center">
      <div
        className="relative w-3/4 sm:w-60 md:w-96 h-3/4 sm:h-60 md:h-96 bg-yellow-500"
        style={{
          clipPath: "polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%)",
        }}
      >
        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-black text-center">
          <p className="text- font-limelight my-6">
            Effortless Invites, Seamless Reservation!
          </p>
          <img
            src="/teemah.png"
            alt="Bottom Decoration"
            className="h-10 w-auto md:h-28 md:block hidden mx-auto"
          />
          <img
            src="/teemah.png"
            alt="Watermark"
            className="absolute bottom-0 left-1/2 transform -translate-x-1/2 opacity-30 block md:hidden"
          />
        </div>
      </div>
      <Link to="/smart-iv">
        <button className="text-sm bg-blue-800 text-yellow-500 p-6 font-bold rounded-lg mt-12">
          Samples of Our Smart E-Invites
        </button>
      </Link>
    </div>

    {/* Right container */}
    <div className="w-full md:w-1/2 font-sans p-6 md:p-8 text-white text-center md:text-left m-auto">
      <div className="text-center my-10">
        <h1 className="text-2xl md:text-4xl font-bold text-yellow-500 mb-4">
          Event Invitation and Reservation LTD
        </h1>
        <p className="text-xl md:text-2xl text-gray-400 mb-6">presents</p>
        <h1 className="text-2xl md:text-3xl font-semibold text-white mb-4">
          Smart E-Invitation Card and Reservation
        </h1>
        <em>
          <p className="text:sm md:text-lg md:text-xl text-gray-300">
            Effortless Invites and Seamless Reservations
          </p>
        </em>
        <p className="mt-20 text-lg text-gray-700 leading-relaxed">
  Send a personalized link to your invitees, allowing them to create their unique invitation cards effortlessly. They can select their preferred table, ensuring a seamless and enjoyable experience for everyone. Make your event planning stress-free and memorable!
</p>
      </div>

      
    </div>
  </div>

  {/* New section below the main content */}
  <div className="bg-gray-100 text-black p-8  rounded-lg mx-4 md:ml-12">
  <h2 className="text-lg md:text-2xl font-semibold mb-4 text-blue-500 text-center">
    Make Your Next Event Unforgettable with Our Services!
  </h2>
  <p className="text-md md:text-lg mb-6 text-justify">
    Organize your next event with ease! From personalized e-invites to seamless table reservations, 
    we provide all the tools you need to create an unforgettable experience for your guests. 
    Let us help you make your event stress-free and memorable!
  </p>
  <div className="text-center">
    <p className="mb-2">
      Have questions or want to get started? Feel free to contact us:
    </p>
    <p className="mb-2">
      <strong>Phone:</strong>{" "}
      <a
        href="tel:+234 706 109 1067"
        className="text-blue-500 hover:underline"
      >
        +234 706 109 1067
      </a>
    </p>
    <p>
      <strong>Email:</strong>{" "}
      <a
        href="mailto:enquiries@teemah.com.ng"
        className="text-blue-500 font-bold hover:underline"
      >
        enquiries@teemah.com.ng
      </a>
    </p>

    {/* Centered buttons */}
    <div className="mt-6 flex flex-col md:flex-row gap-4 justify-center items-center">
      <button
        onClick={handleExploreMore}
        className="bg-yellow-500 text-black px-6 py-3 rounded-lg font-semibold hover:bg-yellow-600 transition duration-300"
      >
        Explore More Events
      </button>
      <Link
        to="/contact"
        className="bg-yellow-500 text-black px-6 py-3 rounded-lg font-semibold hover:bg-yellow-600 transition duration-300"
      >
        Contact Us
      </Link>
      <button
        onClick={() => setIsModalOpen(true)}
        className="bg-yellow-500 text-black px-6 py-3 rounded-lg font-semibold hover:bg-yellow-600 transition duration-300"
      >
        Feedback
      </button>
    </div>
  </div>
</div>

</div>

  );
};

export default ThankYouPage;
