import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { QRCodeCanvas } from "qrcode.react";
import html2canvas from "html2canvas"; // QR code generation library


const Receipt = () => {
    useEffect(() => {
        document.title = "TI | Download IV";
      }, []);
    const { id } = useParams(); // Fetch the invitation ID from the URL
    const [userData, setUserData] = useState(null);
    const [showPopup, setShowPopup] = useState(false);
    const [showPopup2, setShowPopup2] = useState(false);
    const [isDownloadPending, setIsDownloadPending] = useState(false);
    

    useEffect(() => {
        // Fetch the receipt details from the backend using the invitation ID
        const fetchData = async () => {
            try {
                const response = await fetch(`https://teemah.com.ng/api/get-invitation/${id}/`);
                if (!response.ok) {
                    console.error("Failed to fetch data. Status Code:", response.status);
                    throw new Error("Failed to fetch receipt data");
                }

            // try {
            //     const response = await fetch(`http://127.0.0.1:8000/api/get-invitation/${id}/`);
            //     if (!response.ok) {
            //         console.error("Failed to fetch data. Status Code:", response.status);
            //         throw new Error("Failed to fetch receipt data");
            //     }
                const data = await response.json();
                console.log("Fetched data:", data);
                setUserData(data);
    
                // Show pop-up if category matches
                if (["Alumni", "Previous Parents"].includes(data.Category)) {
                    setShowPopup(true);
                }
                // Show pop-up for Prospective Parent
                else if (["Prospective Parents"].includes(data.Category)) {
                    setShowPopup2(true);
                }
            } catch (error) {
                console.error("Error fetching receipt data:", error);
            }
        };
        fetchData();
    }, [id]);
    

  

    const downloadReceiptAsImage = () => {
        
        initiateDownload(); // Proceed to download if no conditions match
    };
    

  const initiateDownload = () => {
      const element = document.getElementById("receipt-container");
      const downloadButton = document.getElementById("download-button");

      if (!element || !downloadButton) {
          console.error("Receipt container or download button not found!");
          return;
      }

      // Hide the download button before rendering
      downloadButton.style.display = "none";

      // Ensure content is fully rendered before capturing the image
      setTimeout(() => {
          html2canvas(element, {
              scale: 3,
              logging: true,
              useCORS: true,
              backgroundColor: "#FFFFFF",
              width: element.offsetWidth,
              height: element.offsetHeight,
              scrollX: 0,
              scrollY: -window.scrollY,
          })
          .then((canvas) => {
            const image = canvas.toDataURL("image/png");
            const link = document.createElement("a");
            link.href = image;
        
            // Set the filename as the user's name
            const filename = `${userData.Name}_event_pass.png`; 
            link.download = filename;
        
            link.click();
        })
              .catch((error) => {
                  console.error("Error capturing the receipt image:", error);
              })
              .finally(() => {
                  downloadButton.style.display = "block"; // Show the button again
              });
      }, 200);
  };

  const closePopup = () => {
      setShowPopup(false);
      if (isDownloadPending) {
          setIsDownloadPending(false); // Reset the pending state
          initiateDownload(); // Proceed with the download
      }
  };

  const closePopup2 = () => {
    setShowPopup2(false);
    if (isDownloadPending) {
        setIsDownloadPending(false); // Reset the pending state
        initiateDownload(); // Proceed with the download
    }
};
  
    
    
    

    // Check if the userData has loaded
    if (!userData) {
        return <p>Loading...</p>;
    }

    

    // Rendering the receipt details safely
    return (
        <div  className="w-full  sm:w-full md:w-full lg:w-full px-4 py-4 min-h-screen bg-blue-100  flex items-center justify-center">
{/* Pop-up Modal */}
           

            {/* Pop-up Modal2 */}
           



<div           

style={{ backgroundImage: "url('/IV/bg7.jpeg')"}}
  id="receipt-container"
  className="  md:mx-auto sm:w-2/3 md:w-3/5 lg:w-2/5 xl:w-3/8  p-6 rounded-lg shadow-lg my-8"
>



    
    <div  className="text-center">
        {/* iv name */}
        
        <p className="font-bold font-sans text-black outline-4 outline-black text-2xl mb-2 bg-white px-2 pt-2 pb-4 rounded-lg">{userData.Name || "N/A"}'s Access Card</p>
        
        {/* iv image */}
        <div className="bg-black-600 p-1 flex flex-col w-full text-black shadow-lg">
    <img src="/IV/bg6.png
    " className="border border-4 border-black rounded rounded-lg object-cover" alt="" />
</div>

<p className="mt-2 text-left font-bold text-white text-2xl">Seat Reservation Information</p>



  <hr className="mt-2 text-yellow-500" />




        {/* User Details Section */}
        <div className="bg-white p-4 rounded-tr-3xl rounded-bl-3xl mt-4 border border-4 border-black">
  
  <div className="flex xs:space-x-2 mt-2 ">
    {/* Left Side: User Data */}
    <div
      
      className="space-y-2 w-3/5 md:w-1/2  text-left border-2 border-black xs:p-2 p-4 rounded-tr-3xl bg-gradient-to-br from-gray-200 to-blue-300 text-white overflow-hidden"
    >
      {/* <p className="flex flex-col mt-2">
        <strong>Category:</strong> <span className="text-yellow-500 font-bold break-line">{userData.Category || "N/A"}</span>
      </p> */}
      <p className="flex  my-2">
        <span className="text-blue-500 font-bold w-1/2 p-2 text-center text-2xl md:text-3xl lg:text-2xl font-poppins medium mr-3">{userData["Table Number"] || "N/A"}</span> 
        
            
            <span className="text-2xl md:text-3xl lg:text-2xl text-center text-blue-500 font-bold border-l-2 border-black   p-2 mb-3 font-poppins medium ">Seat {userData["Seat Number"] || "N/A"}</span>
        
      </p>
      

      

      <p className="flex flex-col text-black border-t-2 border-black p-2 " style={{ fontFamily: 'Georgia, serif', fontStyle: 'italic', fontSize: '14px' }}> <span className="font-bold">Dress Code:</span>  Dress Down (Dress code information here) </p>
      {/* <p>
        <strong>Seats Capacity:</strong> 10
      </p>
      <p>
        <strong>Invitation Id:</strong> {userData["Category"] || "N/A"}/{userData["Invitation Number"] || "N/A"}
      </p> */}
    </div>

    {/* QR Code Section */}
    <div className="flex-none w-2/5 flex flex-col justify-center items-center">
  <QRCodeCanvas
    className="border-white xs:p-1 sm:p-2 p-0 md:p-0" 
    value={JSON.stringify(userData)} 
    size={window.innerWidth < 391 ? 120 : 130} // 100px for small screens, 150px for larger screens
  />
  <p style={{ fontFamily: 'Georgia, serif', fontStyle: 'italic' }} className="text-sm text-black text-center mt-2">
    This event is strictly by invitation and this QR code is personalized for you.
  </p>
</div>

  </div>
</div>
 

<hr className="mt-4 text-yellow-500" />

        
        
{/* <p className="text-sm mt-2 text-white">
    RSVP to 
    <span className="text-white">
        <em><span className="no-underline text-sm">+2348023276543 (Mrs. Shekoni) and +2348030663644 (Mr. Bamsa)</span></em>
    </span>
</p> */}

        
        
    </div>

    {/* Download Button */}
    <button id="download-button"

        onClick={downloadReceiptAsImage}
        className="mt-2 bg-blue-500 text-black font-bold p-3 rounded-lg w-full hover:bg-yellow-500"
        style={{ fontFamily: 'Georgia, serif', fontStyle: 'italic' }}
    >
        Download Access Card
    </button>
    <div className="bg-gradient-to-br from-gray-200 to-blue-300 text-stone-700 py-4 mt-2 text-center ">
  <p style={{ fontFamily: 'Georgia, serif', fontStyle: 'italic', fontSize: '15px' }}className="text-sm xs:text-xs sm:text-xs font-bold">
    <span>@TeemahInvites All rights reserved.</span>
  </p>
  <p style={{ fontSize: '15px' }}className="mt-2 font-bold">
  <span className="no-underline">Contact: +234 706 109 1067</span>
</p>

</div>


{/* Watermark Image */}


</div>

</div>

    );
};

export default Receipt;
