import React, { useEffect, useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import "./admindashboard.css"

const AdminDashboard = () => {
  useEffect(() => {
      document.title = "TI | Admin-dashboard";
    }, []);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [usersPerPage, setUsersPerPage] = useState(20);  // Adjust this to control rows per page
  const [searchTerm, setSearchTerm] = useState('');  // New state for search term
  const [tableNumber, setTableNumber] = useState('');
  const [category, setCategory] = useState('');
  const [sortOrder, setSortOrder] = useState("asc"); // Default sorting order is ascending


  const navigate = useNavigate();


  // Check if the user is authenticated by checking the token in localStorage
  useEffect(() => {
    const token = localStorage.getItem("access_token");  // Use the correct key for your token

    if (!token) {
      Swal.fire({
        title: "Unauthorized",
        text: "You need to log in first.",
        icon: "warning",
        confirmButtonText: "Okay",
      }).then(() => {
        navigate("/admin-login");  // Redirect to login page if no token found
      });
    } else {
      fetchUsers(token);  // Pass the token when calling fetchUsers
    }
  }, [navigate]);

  // Fetch users from the API
  const fetchUsers = async (token) => {
    try {
      const swalInstance = Swal.fire({
        title: "Processing...",
        html: `
            <div class="custom-loading">
                <div class="spinner">
                    <img src="/teemah.png" alt="Loading" />
                </div>
                <p>Kindly allow us a moment to prepare your invitation list.</p>
            </div>
        `,
        showConfirmButton: false, // Hide confirm button during loading
        allowOutsideClick: false,
        allowEscapeKey: false,


      });

      const response = await axios.get("https://teemah.com.ng/api/registered-users/", {
        headers: {
          Authorization: `Bearer ${token}`, // Include the token in the request header
        },
      });

      // const response = await axios.get("http://127.0.0.1:8000/api/registered-users/", {
      //   headers: {
      //     Authorization: `Bearer ${token}`, // Include the token in the request header
      //   },
      // });
      // console.log(response.data);


      // Close the Swal modal when data is fetched.
      swalInstance.close();


      if (Array.isArray(response.data.users)) {
        const sortedUsers = response.data.users.sort((a, b) => b.present - a.present);
        setUsers(sortedUsers);
      } else {
        console.error("Expected 'users' key to be an array but received:", response.data);
        Swal.fire({
          title: "Error!",
          text: "Received data format is incorrect.",
          icon: "error",
          confirmButtonText: "Okay",
        });
      }
    } catch (error) {
      console.error("Error fetching registered users:", error);
      Swal.fire({
        title: "Error!",
        text: "Failed to fetch users. Please try again later.",
        icon: "error",
        confirmButtonText: "Okay",
      });
    } finally {
      setLoading(false);
    }
  };

  const totalPresent = users.filter((user) => user.present).length;
  const totalAbsent = users.length - totalPresent;


  const togglePresent = async (id) => {
    try {
      const user = users.find((u) => u.id === id);
      if (!user) {
        console.error(`User with ID ${id} not found.`);
        return;
      }

      const newStatus = user.present ? "absent" : "present";

      // const response = await axios.patch(
      //   `http://127.0.0.1:8000/api/users/${id}/mark_present/`,
      //   { status: newStatus },
      //   { headers: { "Content-Type": "application/json" } }
      // );

      const response = await axios.patch(
        `https://teemah.com.ng/api/users/${id}/mark_present/`,
        { status: newStatus },
        { headers: { "Content-Type": "application/json" } }
      );

      if (response.status === 200) {
        const updatedUser = response.data.user;
        setUsers((prevUsers) =>
          prevUsers.map((u) =>
            u.id === id ? { ...u, present: updatedUser.present, timestamp_marked: updatedUser.timestamp_marked } : u
          )
        );
      } else {
        console.error("Unexpected response:", response);
      }
    } catch (error) {
      console.error("Error toggling presence:", error);
      Swal.fire({
        title: "Error!",
        text: "Failed to toggle user presence. Please try again later.",
        icon: "error",
        confirmButtonText: "Okay",
      });
    }
  };


  // Pagination logic
  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  const currentUsers = users
    .filter((user) =>
      user.name.toLowerCase().includes(searchTerm.toLowerCase()) &&  // Filter by name
      (tableNumber === '' || (user.tableNumber && user.tableNumber.toString() === tableNumber)) &&  // Ensure tableNumber exists before calling toString()
      (category === '' || user.category === category)  // Filter by category
    )
    .slice(indexOfFirstUser, indexOfLastUser);



  // Sorting function
  // const handleSort = (order) => {
  //   const sortedUsers = [...users].sort((a, b) => {
  //     return order === "asc" ? a.table_number - b.table_number : b.table_number - a.table_number;
  //   });
  //   setUsers(sortedUsers); // Update the users state with the sorted data
  //   setSortOrder(order); // Update the current sort order
  // };

  const handleSort = (field, order) => {
    const sortedUsers = [...users].sort((a, b) => {
      if (field === "timestamp_marked") {
        // Convert to Date objects for comparison
        const dateA = new Date(a.timestamp_marked);
        const dateB = new Date(b.timestamp_marked);
        return order === "asc" ? dateA - dateB : dateB - dateA;
      } else if (field === "table_number") {
        // Sorting by table number
        return order === "asc" ? a.table_number - b.table_number : b.table_number - a.table_number;
      }
      return 0;
    });
    setUsers(sortedUsers);
    setSortOrder(order);
  };





  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handleUsersPerPageChange = (e) => {
    setUsersPerPage(parseInt(e.target.value, 10));
    setCurrentPage(1); // Reset to first page whenever usersPerPage changes
  };

  const totalPages = Math.ceil(users.length / usersPerPage);

  














  return (
    <div className="min-h-screen bg-black p-8 text-white ml-0 md:ml-8 w-full">
      <h1 className="text-2xl md:text-4xl font-bold text-center  mb-8 text-yellow-500">Admin Dashboard - Registered Users</h1>

      {/* User Stats */}
      <div className="flex flex-col sm:flex-row justify-center mb-8 space-y-4 sm:space-y-0 sm:space-x-6 text-center ">
        <div className="bg-yellow-500 text-black px-6 py-3 rounded-lg shadow-xl transform hover:scale-105 transition-transform duration-300 text-center">
          <span className="font-bold text-2xl">{users.length}</span> Total Users
        </div>
        <div className="bg-green-500 text-black px-6 py-3 rounded-lg shadow-xl transform hover:scale-105 transition-transform duration-300">
          <span className="font-bold text-2xl">{totalPresent}</span> Present
        </div>
        <div className="bg-red-500 text-black px-6 py-3 rounded-lg shadow-xl transform hover:scale-105 transition-transform duration-300">
          <span className="font-bold text-2xl">{totalAbsent}</span> Absent
        </div>
      </div>

      {/* Event Registration Button */}
      <div className="text-center mb-8">
        <button
          onClick={() => navigate("/scan-qr")}
          className="bg-yellow-500 text-black py-3 px-6 rounded-full shadow-lg transform hover:scale-105 transition-transform duration-300"
        >
          Event Registration
        </button>
      </div>

      <div className="flex flex-col md:flex-row md:justify-between items-center space-y-4 md:space-y-0 md:space-x-4">
  {/* Search Bar */}
  <div className="text-center mb-4 md:mb-0">
    <input
      type="text"
      value={searchTerm}
      onChange={(e) => setSearchTerm(e.target.value)} // Update the search term
      placeholder="Search by Name"
      className="py-2 px-4 bg-yellow-500 text-black rounded-md shadow-md"
    />
  </div>

  {/* Sorting Dropdown */}
  {/* <div className="mb-4 md:mb-0">
    <label htmlFor="sortOrder" className="mr-2">
      Sort by Table Number:
    </label>
    <select
      id="sortOrder"
      value={sortOrder}
      onChange={(e) => handleSort(e.target.value)} // Trigger sorting on selection
      className="border rounded px-2 py-1 text-black"
    >
      <option value="asc">Ascending</option>
      <option value="desc">Descending</option>
    </select>
  </div> */}
<div className="mb-4 md:mb-0">
  <label htmlFor="sortField" className="mr-2">
    Sort by:
  </label>
  <select
    id="sortField"
    onChange={(e) => {
      const [field, order] = e.target.value.split(",");
      handleSort(field, order);
    }}
    className="border rounded px-2 py-1 text-black"
  >
    <option value="table_number,asc">Table Number: Ascending</option>
    <option value="table_number,desc">Table Number: Descending</option>
    <option value="timestamp_marked,asc">Time In: Ascending</option>
    <option value="timestamp_marked,desc">Time In: Descending</option>
  </select>
</div>;

  {/* Users Per Page */}
  <div className="text-center">
    <label htmlFor="usersPerPage" className="mr-4">
      Users Per Page:
    </label>
    <select
      id="usersPerPage"
      value={usersPerPage}
      onChange={handleUsersPerPageChange}
      className="py-2 px-4 bg-yellow-500 text-black rounded-md shadow-md"
    >
      <option value="20">20</option>
      <option value="30">30</option>
      <option value="40">40</option>
      <option value="50">50</option>
      <option value="100">100</option>
      <option value="150">150</option>
      <option value="200">200</option>
      <option value="300">300</option>
    </select>
  </div>
</div>


      {/* Loading or No Users Message */}
      {loading ? (
        <div className="flex justify-center">
          <div className="text-lg">Loading...</div>
        </div>
      ) : users.length === 0 ? (
        <div className="flex justify-center">
          <div className="text-lg">No registered users found.</div>
        </div>
      ) : (
        <>
          <div className="overflow-x-auto max-w-full mt-8 rounded-lg">
            <table className="min-w-full bg-white text-black table-auto  shadow-lg table-fixed">
              <thead className="bg-yellow-500 text-black">
                <tr>
                  <th className="py-3 px-6  font-semibold uppercase text-sm">S/N</th>
                  <th className="py-3 px-6  font-semibold uppercase text-sm">Name</th>
                  <th className="py-3 px-6  font-semibold uppercase text-sm hidden sm:table-cell">Email</th>
                  <th className="py-3 px-6  font-semibold uppercase text-sm hidden sm:table-cell">Category</th>
                  <th className="py-3 px-6  font-semibold uppercase text-sm">Table Number</th>
                  <th className="py-3 px-6  font-semibold uppercase text-sm ">Seat Number</th>
                  <th className="py-3 px-6  font-semibold uppercase text-sm hidden sm:table-cell">Time Registered</th>
                  <th className="py-3 px-6 font-semibold uppercase text-sm">Register</th>
                  <th className="py-3 px-6 font-semibold uppercase text-sm hidden sm:table-cell">Time In</th>
                </tr>
              </thead>
              <tbody>
                {currentUsers.map((user, index) => (
                  <tr
                    key={user.id}
                    className={`${index % 2 === 0 ? "bg-gray-100" : "bg-gray-200"} hover:bg-gray-300`}
                  >
                    <td className="py-3 px-6 text-gray-700">{indexOfFirstUser + index + 1} </td>
                    <td className="py-3 px-6 text-gray-700 break-words">{user.name}</td>
                    <td className="py-3 px-6 text-gray-700 break-all hidden sm:table-cell">{user.email}</td>
                    <td className="py-3 px-6 text-gray-700 break-all hidden sm:table-cell">{user.category}</td>
                    <td className="py-3 px-6 text-gray-700 break-words">{user.table_number}</td>
                    <td className="py-3 px-6 text-gray-700 break-words">{user.seat_number}</td>
                    {/* <td className="py-3 px-6 text-gray-700 break-words">
                    {new Date(user.registered_at).toLocaleString()}
                  </td> */}
                    <td className="py-3 px-6 text-gray-700 break-words hidden sm:table-cell">
                      {user.registered_at
                        ? new Intl.DateTimeFormat('en-NG', {
                          timeZone: 'Africa/Lagos',
                          year: 'numeric',
                          month: 'short',
                          day: '2-digit',
                          hour: '2-digit',
                          minute: '2-digit',
                          second: '2-digit',
                        }).format(new Date(user.registered_at))
                        : "Not Registered"}
                    </td>

                    <td className="py-3 px-6 text-gray-700">
                      <div className="flex items-center space-x-2">
                        <div
                          onClick={() => togglePresent(user.id)} // Pass the user's ID to the toggle function
                          className={`w-12 h-6 flex items-center rounded-full cursor-pointer p-1 ${user.present ? "bg-green-500" : "bg-red-500"
                            }`}
                        >
                          <div
                            className={`w-5 h-5 bg-white rounded-full shadow-md transform transition-transform ${user.present ? "translate-x-6" : "translate-x-0"
                              }`}
                          ></div>
                        </div>
                        <span>{user.present ? "Present" : "Absent"}</span>
                      </div>
                    </td>









                    {/* <td className="py-3 px-6 text-gray-700">{user.present ? "Yes" : "No"}</td> */}
                    <td className="py-3 px-6 text-gray-700 break-words hidden sm:table-cell">
                      {user.timestamp_marked
                        ? new Date(user.timestamp_marked).toLocaleString()
                        : "Not Marked"}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {/* Pagination */}
          <div className="flex justify-center mt-8">
            <ul className="flex space-x-2">
              {Array.from({ length: totalPages }, (_, i) => i + 1).map((number) => (
                <li key={number}>
                  <button
                    onClick={() => paginate(number)}
                    className={`px-4 py-2 rounded-md ${currentPage === number
                      ? "bg-yellow-500 text-black"
                      : "bg-gray-200 text-gray-700"
                      }`}
                  >
                    {number}
                  </button>
                </li>
              ))}
            </ul>
          </div>
        </>
      )}
    </div>
  );
};

export default AdminDashboard;
