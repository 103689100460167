import React, {useEffect} from "react";
import { Link } from "react-router-dom";

const ContactPage = () => {
  useEffect(() => {
      document.title = "TI | Contact";
    }, []);
  return (
    <div className="min-h-screen bg-black text-white flex flex-col items-center justify-center p-6">
      {/* Header Section */}
      <div className="text-center mb-8">
        <h1 className="text-3xl md:text-5xl font-bold mb-4 text-yellow-500">
          Get in Touch with Us
        </h1>
        <p className="text-lg md:text-xl">
          We're here to assist you with any questions or inquiries about our services.
        </p>
      </div>

      {/* Contact Information Section */}
      <div className="bg-yellow-500 text-black rounded-lg p-6 w-full md:w-1/2 text-center">
        <p className="text-xl md:text-2xl font-semibold mb-4">
          Contact Information
        </p>
        <p className="mb-4 text-lg">
          <strong>Phone:</strong>{" "}
          <a href="tel:+2347061091067" className="underline hover:text-yellow-700">
          +234 706 109 1067
          </a>
        </p>
        <p className="mb-4 text-lg">
          <strong>Email:</strong>{" "}
          <a
            href="mailto:enquiries@teemah.com.ng"
            className="underline hover:text-yellow-700"
          >
            enquiries@teemah.com.ng
          </a>
        </p>
        <p className="text-md md:text-lg">
          We look forward to helping you create an unforgettable experience!
        </p>
      </div>

      {/* Back to Home Button */}
      <div className="mt-8">
        <Link
          to="/"
          className="bg-yellow-500 text-black px-6 py-3 rounded-lg font-semibold hover:bg-yellow-600 transition duration-300"
        >
          Back to Home
        </Link>
      </div>
    </div>
  );
};

export default ContactPage;
