import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Sidebar from "./Components/Sidebar";
import ThankYouPage from "./Pages/ThankyouPage";
import LandingPage from "./Pages/LandingPage";
import InvitationForm from "./Pages/InvitationForm";
import OjoReceipt from "./Components/OjoReceipt";
import AdminLogin from "./Pages/AdminPage";
import AdminDashboard from "./Pages/AdminDashboard";
import QrScannerPage from "./Pages/QrScannerPage";
import ExploreMore from "./Pages/ExploreMore";
import ContactPage from "./Pages/ContactPage";
import RegReceipt from "./Pages/RegReceipt";
import SmartIv from "./Components/SmartIv";
import EventPackages from "./Pages/EventPackages"

const App = () => {
  const [sidebarCollapsed, setSidebarCollapsed] = useState(false);

  const toggleSidebarCollapse = () => {
    setSidebarCollapsed((prev) => !prev);
  };

  

  return (
    <Router>
      <div className="flex min-h-screen bg-yellow-500">
        {/* Sidebar */}
        <Sidebar
          collapsed={sidebarCollapsed}
          toggleCollapse={toggleSidebarCollapse}
        />

        {/* Main Content */}
        <div
          className={`flex-1 bg-black text-white p-6 transition-all duration-300 ${
            sidebarCollapsed ? "ml-16" : "ml-0"
          }`}
        >
          <Routes>
            <Route path="/" element={<ThankYouPage />} />
            <Route path="/go-to-receipt" element={<LandingPage />} />
            <Route path="/create-invitation" element={<InvitationForm />} />
            <Route path="/receipt/:id" element={<OjoReceipt />} />
            <Route path="/admin-login" element={<AdminLogin />} />
            <Route path="/admin-dashboard" element={<AdminDashboard />} />
            <Route path="/scan-qr" element={<QrScannerPage />} />
            <Route path="/explore-more" element={<ExploreMore />} />
            <Route path="/contact" element={<ContactPage />} />
            <Route path="/reg-email" element={<RegReceipt />} />
            <Route path="/smart-iv" element={<SmartIv />} />
            <Route path="/packages" element={<EventPackages />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
};

export default App;
