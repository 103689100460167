import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import axios from "axios";

const RegReceipt = () => {
  useEffect(() => {
      document.title = "TI | Registration";
    }, []);
  const [formData, setFormData] = useState({
    invitee_name: "",
    invitee_email: "",
    phone_number: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    Swal.fire({
              title: "Processing...",
              html: `
                  <div class="custom-loading">
                      <div class="spinner">
                          <img src="/teemah.png" alt="Loading" />
                      </div>
                      <p>Please hold on while your registration is being processed."</p>
                  </div>
              `,
              showConfirmButton: false, // Hide confirm button during loading
              allowOutsideClick: false,
              allowEscapeKey: false,
              
        
          });

    try {
      // Send the POST request to create a new invitee
      const response = await axios.post("https://teemah.com.ng/api/invitees/", formData);

      // Assuming the response contains the invitee's name and serial number
      const { invitee_name, serial_number } = response.data;

      // Show the SweetAlert2 message
      Swal.fire({
        title: `Your Registration number is ${serial_number}`,
        html: `
            <div style="padding: 20px; border-radius: 8px; font-family: Arial, sans-serif;">
                <h2 style="color: #2c3e50; font-size: 1.5rem; text-align: center; margin-bottom: 10px;">Invitation Confirmation</h2>
                <p style="color: #34495e; font-size: 1.1rem; line-height: 1.6;">
                    Dear <strong style="color: #27ae60;">${invitee_name}</strong>,<br>
                    Thank you for joining us at <strong style="color: #27ae60;">Mrs. Oluwatoyin Ojo's</strong> birthday celebration.
                </p>
                
                <p style="color:#e33011; font-size: 1.1rem; line-height: 1.6;">
                    You may now proceed to scan your invitation card.
                </p>
            </div>
        `,
        icon: "success",
        confirmButtonText: "Okay",
        customClass: {
            popup: 'custom-swal-popup'  // Apply custom class to popup container
        }
    });
    
    // CSS to target the popup background:
    const style = document.createElement('style');
    style.innerHTML = `
        .custom-swal-popup {
            background-color:rgb(246, 187, 142) !important; /* Set the background color for the entire popup */
        }
    `;
    document.head.appendChild(style);
    
    
    } catch (error) {
      Swal.fire({
        title: "Error",
        text: "An error occurred while submitting your invitation. Please try again.",
        icon: "error",
        confirmButtonText: "Try Again",
      });
    }
  };

  return (
    <div className="relative min-h-screen bg-black flex items-center justify-center px-0 md:px-2 sm:px-1">
      <div className="max-w-md w-full bg-gradient-to-br from-yellow-500 to-orange-800 shadow-lg rounded-tr-3xl rounded-bl-3xl p-8 z-15 my-8">
        <h1 className="text-3xl font-bold font-limelight text-center text-blue-800 mb-6">
          Registration Form
        </h1>
        <form onSubmit={handleSubmit} className="space-y-6">
          <div>
            <label className="block font-medium mb-1 text-black">Name:</label>
            <input
              type="text"
              name="invitee_name"
              value={formData.invitee_name}
              onChange={handleChange}
              required
              className="w-full border border-gray-300 rounded-lg p-3 focus:outline-none focus:ring-2 focus:ring-gray-500 text-black"
            />
          </div>
          <div>
            <label className="block font-medium mb-1 text-black">Email:</label>
            <input
              type="email"
              name="invitee_email"
              value={formData.invitee_email}
              onChange={handleChange}
              required
              className="w-full border border-gray-300 rounded-lg p-3 focus:outline-none focus:ring-2 focus:ring-gray-500 text-black"
            />
          </div>
          <div>
            <label className="block font-medium mb-1 text-black">Phone Number:</label>
            <input
              type="text"
              name="phone_number"
              value={formData.phone_number}
              onChange={handleChange}
              required
              className="w-full border border-gray-300 rounded-lg p-3 focus:outline-none focus:ring-2 focus:ring-gray-500 text-black"
            />
          </div>

          <div className="text-center">
            <button
              type="submit"
              className="bg-blue-800 text-white px-6 py-3 rounded-lg shadow hover:bg-yellow-600 transition-colors"
            >
              Submit Invitation
            </button>
          </div>
        </form>
          {/* Watermark Image */}
    <img
      src="/teemah.png" // Replace with your watermark image path
      alt="Watermark"
      className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 opacity-10 w-3/4 sm:w-3/3 md:w-1/4 lg:w-1/6 pointer-events-none"
    />
      </div>
    </div>
  );
};

export default RegReceipt;
