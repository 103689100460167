import React, {useEffect, useState} from 'react';
import { Link } from "react-router-dom";

const EventPackages = () => {
     // Array of image URLs
  const images = [
    "/IV/elite1.png",
    "/IV/elite2.png",
    "/IV/elite3.png",
    "/IV/elite4.png",
   
  ];

  // State for the random image
  const [randomImage, setRandomImage] = useState("");

  useEffect(() => {
    // Function to select a random image
    const changeImage = () => {
      const randomIndex = Math.floor(Math.random() * images.length);
      setRandomImage(images[randomIndex]);
    };

    // Set an initial random image
    changeImage();

    // Change the image every 5 seconds (5000 milliseconds)
    const intervalId = setInterval(changeImage, 5000);

    // Cleanup the interval when the component is unmounted
    return () => clearInterval(intervalId);
  }, []); // Empty dependency array means this effect runs once when the component mounts

  return (
    <div className="bg-black text-white py-16 ml-1 md:ml-8">
      <div className="container mx-auto px-0 md:px-6">
        <h1 className="text-2xl md:text-3xl lg:text-4xl font-bold text-center bg-yellow-500 rounded-lg p-4 text-blue-500 mb-12">Our Event Packages</h1>

        {/* Simple Gather Package Card */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
          <div className="bg-gray-100 text-black p-1 md:p-6 rounded-lg shadow-lg">
            <img
              src="/IV/Package2.jpg"
              alt="Simple Gather"
              className="w-full h-48 object-cover rounded-lg mb-4"
            />
            <h2 className="text-2xl font-semibold text-yellow-500 mb-4">Simple Gather (Free Package)</h2>
            <p className="text-lg mb-4">
              Ideal for small gatherings that are strictly by invitation (IV). Choose from the following:
            </p>
            <ul className="list-disc list-inside mb-4">
              <li>50 Access Cards without QR codes.</li>
              <li>20 Access Cards with QR codes.</li>
            </ul>
            <p className="text-black mb-4">
              <strong>Best For:</strong> Intimate gatherings like birthday parties, family meetups, or small reunions.
            </p>
          </div>

          {/* Event Essential Package Card */}
          <div className="bg-gray-100 text-black p-1 md:p-6 rounded-lg shadow-lg">
            <img
              src="/IV/package1.png"
              alt="Event Essential"
              className="w-full h-48 object-cover rounded-lg mb-4"
            />
            <h2 className="text-2xl font-semibold text-yellow-500 mb-4">Event Essential Package</h2>
            <p className="text-lg mb-4">
              Ideal for events that require registration and a clear headcount. This package includes:
            </p>
            <ul className="list-disc list-inside mb-4">
              <li>Unlimited Invitations without QR codes.</li>
              <li>Invitees are counted for headcount.</li>
              <li>Helps your invites have a sense of belonging.</li>
            </ul>
            <p className="text-black mb-4">
              <strong>Best For:</strong> Corporate events, seminars, or large celebrations.
            </p>
          </div>

          {/* Elite Access Package Card */}
          <div className="bg-gray-100 text-black p-1 md:p-6 rounded-lg shadow-lg">
            <img
              src={randomImage}
              alt="Elite Access"
              className="w-full h-60 object-fit rounded-lg mb-4"
            />
            <h2 className="text-2xl font-semibold text-yellow-500 mb-4">Elite Access Package (Premium)</h2>
            <p className="text-lg mb-4">
              Ideal for luxurious, toosh, and highly organized parties/events. Includes:
            </p>
            <ul className="list-disc list-inside mb-4">
              <li>Price based on the number of invitees planned.</li>
              <li>Access Cards with QR codes for scanning.</li>
              <li>Admin rights for editing guest lists and managing invite details.</li>
              <li>Seat reservations for attendees.</li>
            </ul>
            <p className="text-black mb-4">
              <strong>Additional Features (Available on Request):</strong>
            </p>
            <ul className="list-disc list-inside mb-4">
              <li>Custom-designed Table Tags.</li>
              <li>Personalized Paper Tags.</li>
            </ul>
            <p className="text-black mb-4">
              <strong>Best For:</strong> Exclusive events, galas, weddings, or private luxury parties.
            </p>
          </div>
        </div>

        {/* Call to Action Button */}
        <div className="text-center mt-8">
          <Link to="/contact"><button className="bg-blue-500 text-white py-2 px-6 rounded-lg text-lg hover:bg-blue-600 transition duration-300">
            Get in Touch to Book Your Package
          </button></Link>
        </div>
      </div>
    </div>
  );
};

export default EventPackages;
