import React from "react";
import { useNavigate } from "react-router-dom";

const SmartIv = () => {
  const navigate = useNavigate();

  const invitations = [
    { id: 1, title: "Celebrating Together", image: "/IV/iv1.png" },
    { id: 2, title: "Join the Fun", image: "/IV/iv3.png" },
    { id: 3, title: "Thank You for Coming", image: "/IV/iv5.png" },
  ];

  return (
    <div className="min-h-screen bg-black text-white flex flex-col items-center justify-center">
      <h1 className="text-lg sm:text-2xl md:text-4xl text-yellow-500 font-bold mt-4 mb-8 text-center">
        Samples of Smart Electronic Invitation Cards
      </h1>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-8 w-full max-w-5xl px-4 mx-auto">
        {invitations.map((invite) => (
          <div
            key={invite.id}
            className="bg-white shadow-lg rounded-lg border-4 border-yellow-500 overflow-hidden flex flex-col items-center"
          >
            <div className="w-full h-90">
              <img
                src={invite.image}
                alt={invite.title}
                className="w-full h-full object-fit "
              />
            </div>
            <div className="p-4 text-black text-center">
              <h2 className="text-md font-bold">{invite.title}</h2>
              <p className="text-sm">
                Celebrate with us and make lasting memories!
              </p>
            </div>
          </div>
        ))}
      </div>
      <button
        onClick={() => navigate(-1)}
        className="mt-6 bg-yellow-500 text-black px-6 py-3 rounded-lg font-semibold hover:bg-yellow-600 transition"
      >
        Go Back
      </button>
    </div>
  );
};

export default SmartIv;
