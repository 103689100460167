import React, {useEffect} from "react";
import { Link } from "react-router-dom";

const ExploreMorePage = () => {
  useEffect(() => {
      document.title = "TI | Explore-More";
    }, []);
  return (
    <div className="min-h-screen bg-black text-white flex flex-col ml-0 md:ml-8">
      {/* Header Section */}
      <header className="flex flex-col items-center justify-center text-center py-12 px-4 bg-black">
        <h1 className="text-3xl md:text-5xl font-bold mb-4 text-yellow-500">
          Discover the Power of E-Invites & Seamless Reservations
        </h1>
        <p className="text-lg md:text-xl mb-8">
          Revolutionize your event planning experience with modern tools that bring ease and efficiency.
        </p>
        <Link
          to="/contact"
          className="bg-yellow-500 text-black px-8 py-4 rounded-lg font-semibold hover:bg-yellow-600 transition duration-300"
        >
          Start Now
        </Link>
      </header>

      {/* Benefits Section */}
      <section className="py-12 px-6 bg-black">
        <h2 className="text-2xl md:text-4xl font-bold text-center mb-8 text-yellow-500">
          Why Choose E-Invites & Reservations?
        </h2>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {[
            "Accurate RSVP Tracking - Know exactly who’s attending your event.",
            "Eco-Friendly Alternative - Save paper and go green.",
            "Customizable Categories - Organize your invitees by groups or preferences.",
            "Effortless Information Sharing - Share event details like a printed invite.",
            "Table Reservations - Let guests choose their preferred seating.",
            "Modern QR Codes - Convenient for quick access to event details.",
            "Ease for All Guests - Simple links for those less tech-savvy."
          ].map((benefit, index) => (
            <div
              key={index}
              className="bg-yellow-200 text-black p-6 rounded-lg shadow-md text-center"
            >
              <p className="font-semibold text-md md:text-lg">{benefit}</p>
            </div>
          ))}
        </div>
      </section>

      {/* For Less Tech-Savvy Guests */}
      <section className="py-12 px-6 bg-white text-black">
        <h2 className="text-2xl md:text-4xl font-bold text-center mb-4">
          Worried About Your Guests’ Tech Skills?
        </h2>
        <p className="text-md md:text-lg text-center max-w-3xl mx-auto">
          No worries! We offer simple options to ensure everyone can participate,
          including printable invites or assistance with setup. Just send us your
          link, and we’ll generate the e-invite for you effortlessly!
        </p>
      </section>

      {/* How It Works Section */}
      <section className="py-12 px-6 bg-black">
        <h2 className="text-2xl md:text-4xl font-bold text-center mb-8 text-yellow-500">
          Generate Your E-Invite in Just 3 Steps!
        </h2>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {[
            "Step 1: Share your event details.",
            "Step 2: Customize your invite and categories.",
            "Step 3: Distribute your invite link and track RSVPs."
          ].map((step, index) => (
            <div
              key={index}
              className="bg-yellow-200 text-black p-6 rounded-lg shadow-md text-center"
            >
              <p className="font-semibold text-md md:text-lg">{step}</p>
            </div>
          ))}
        </div>
      </section>

      {/* Footer Call-to-Action */}
      <footer className="flex flex-col items-center justify-center py-12 px-4 bg-black">
        <Link
          to="/contact"
          className="bg-yellow-500 text-black px-8 py-4 rounded-lg font-semibold hover:bg-yellow-600 transition duration-300"
        >
          Get Started Now
        </Link>
        <p className="text-sm mt-4">
          Need help? <Link to="/contact" className="text-yellow-500 hover:underline">Contact us</Link>.
        </p>
      </footer>
    </div>
  );
};

export default ExploreMorePage;
