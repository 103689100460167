import React, { useState, useEffect } from "react";
import QrScanner from "react-qr-scanner";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

const QrScannerPage = () => {
  useEffect(() => {
      document.title = "TI | Scan";
    }, []);
  const [scanning, setScanning] = useState(false);
  const [cameraAvailable, setCameraAvailable] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false); // To store authentication status
  const [facingMode, setFacingMode] = useState("environment"); // "environment" for back camera, "user" for front
  const navigate = useNavigate(); // Hook to navigate programmatically

  useEffect(() => {
    // Check if there is an access token in the local storage
    const token = localStorage.getItem("access_token");
    if (token) {
      setIsAuthenticated(true); // User is authenticated
    } else {
      setIsAuthenticated(false); // No token found, user is not authenticated
      navigate("/admin-login"); // Redirect to admin login page if no token is found
    }

    // Check if getUserMedia is available
    if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
      setCameraAvailable(false);
      Swal.fire({
        title: "Camera Not Available",
        text: "Your device or browser does not support QR code scanning.",
        icon: "error",
        confirmButtonText: "Okay",
      });
    }
  }, [navigate]); // Depend on navigate to avoid stale closure issues

  const handleScan = (data) => {
    if (data && data.text) {
      setScanning(false); // Stop scanning after a successful scan

      try {
        // Parse the scanned QR code data
        const scannedData = JSON.parse(data.text);

        // Extract the Invitation Number
        const invitationId = scannedData["Invitation Number"]; // Adjust the key to match your backend

        if (!invitationId) {
          throw new Error("Invalid QR code: Invitation Number not found");
        }

        // Send a PATCH request to the backend to mark the user as present
        // fetch(`http://127.0.0.1:8000/api/users/${invitationId}/mark_present/`, {
        //   method: "PATCH",
        fetch(`https://teemah.com.ng/api/users/${invitationId}/mark_present/`, {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ present: true }), // Update only the present field
        })
          .then((response) => response.json()) // Get the response as JSON
          .then((result) => {
            console.log(result); // Check the response data for debugging

            if (result.user) {
              const userName = result.user.name || "User";
              const seatNumber = result.user.seat_number;
              const tableNumber = result.user.table_number || "N/A"; // Fallback if 'name' is missing
              if (result.message === "User marked as present.") {
                // First scan, user is successfully marked as present
                Swal.fire({
                  title: "Marked as Present",
                  html: `<strong> ${userName}</strong> has been marked as present.  <p><strong>Table: ${tableNumber} <strong> --> Seat: ${seatNumber}</p>`,
                  icon: "success",
                  confirmButtonText: "Okay",
                  customClass: {
                    popup: 'bg-yellow-100 border-4 border-yellow-500 text-black w-11/12 md:w-1/4', // Popup styling
                    title: 'text-black font-bold', // Title styling
                    content: 'text-black', // Content text styling
                    confirmButton: 'bg-yellow-500 text-black font-bold p-4 rounded hover:bg-yellow-600' // Confirm button styling
                  }
                });
              } else if (result.message === "QR code is already in use. User is already marked as present.") {
                // QR code already used
                Swal.fire({
                  title: "QR Code Already Used",
                  html: `<strong> ${userName} </strong> has already been marked as present.  <p><strong>Table: ${tableNumber} <strong> --> Seat: ${seatNumber}</p>`,
                  icon: "warning",
                  confirmButtonText: "Okay",
                  customClass: {
                    popup: 'bg-yellow-100 border-4 border-yellow-500 text-black w-11/12 md:w-1/4', // Popup styling
                    title: 'text-black font-bold', // Title styling
                    content: 'text-black', // Content text styling
                    confirmButton: 'bg-yellow-500 text-black font-bold p-4 rounded hover:bg-yellow-600' // Confirm button styling
                  }
                });
              }
            } else {
              // Handle unexpected responses
              console.error("Unexpected response:", result);
              throw new Error(result.message || "An unexpected error occurred.");
            }
          })
          .catch((error) => {
            console.error("Error updating invitation status:", error);
            Swal.fire({
              title: "Error",
              text: `Could not mark the user as present. Error: ${error.message}`,
              icon: "error",
              confirmButtonText: "Okay",
              customClass: {
                popup: 'bg-yellow-100 border-4 border-yellow-500 text-black w-11/12 md:w-1/4', // Popup styling
                title: 'text-black font-bold', // Title styling
                content: 'text-black', // Content text styling
                confirmButton: 'bg-yellow-500 text-black font-bold p-4 rounded hover:bg-yellow-600' // Confirm button styling
              }
            });
          });
      } catch (error) {
        console.error("Error parsing QR code data:", error);
        Swal.fire({
          title: "Invalid QR Code",
          text: "The scanned QR code contains invalid data.",
          icon: "error",
          confirmButtonText: "Okay",
          customClass: {
            popup: 'bg-yellow-100 border-4 border-yellow-500 text-black w-11/12 md:w-1/4', // Popup styling
            title: 'text-black font-bold', // Title styling
            content: 'text-black', // Content text styling
            confirmButton: 'bg-yellow-500 text-black font-bold p-4 rounded hover:bg-yellow-600' // Confirm button styling
          }
        });
      }
    }
  };

  const handleError = (err) => {
    console.error(err);
    Swal.fire({
      title: "Error",
      text: "There was an error scanning the QR code.",
      icon: "error",
      confirmButtonText: "Okay",
      customClass: {
        popup: 'bg-yellow-100 border-4 border-yellow-500 text-black w-11/12 md:w-1/4', // Popup styling
        title: 'text-black font-bold', // Title styling
        content: 'text-black', // Content text styling
        confirmButton: 'bg-yellow-500 text-black font-bold p-4 rounded hover:bg-yellow-600' // Confirm button styling
      }
    });
  };

  const startScanning = () => {
    setScanning(true);
  };

  const stopScanning = () => {
    setScanning(false);
  };

   // Toggle between front and back camera
   const toggleCamera = () => {
    setFacingMode(facingMode === "environment" ? "user" : "environment");
  };

  return (
    <div className="min-h-screen bg-gray-800 p-8 relative ">
      <h1 className="text-3xl font-bold text-white mb-6 text-center">
        QR Scanner
      </h1>

      {/* Show message if camera is not available */}
      {!cameraAvailable && (
        <div className="text-center text-white mb-6">
          Camera access is not supported on this device or browser.
        </div>
      )}

      {/* Button to toggle QR scanning */}
      <div className="flex justify-center mb-6">
        {!scanning ? (
          <button
            onClick={startScanning}
            className="bg-blue-500 text-white py-2 px-6 rounded-lg shadow-lg"
          >
            Start Scanning
          
          </button>
        ) : (
          <button
            onClick={stopScanning}
            className="bg-red-500 text-white py-2 px-6 rounded-lg shadow-lg"
          >
            Stop Scanning
          </button>
        )}
      </div>

      {/* Show QR scanner only if scanning is active */}
      {scanning && cameraAvailable && (
        <div className="flex justify-center items-center">
          <QrScanner
  delay={300}
  style={{ width: "80%", height: "80%" }}
  className="w-4/5 h-4/5 md:w-2/5 md:h-2/5"
  onScan={handleScan}
  onError={handleError}
  constraints={{
    video: {
      facingMode: facingMode,
      width: { ideal: 1280 }, // Ideal width for high resolution
      height: { ideal: 720 }, // Ideal height for high resolution
    },
  }}
/>


        </div>
      )}

      {/* Toggle camera button */}
      {cameraAvailable && (
        <div className="flex justify-center mt-6">
          <button
            onClick={toggleCamera}
            className="bg-green-500 text-white py-2 px-6 rounded-lg shadow-lg"
          >
            Toggle Camera
          </button>
        </div>
      )}
    </div>
  );
};

export default QrScannerPage;
